import axios from "axios";
import {useEffect, useRef, useState} from "react";
import {Button, FormCheck, Modal} from "react-bootstrap";
import NavBarMain from "../../componentes/layoutBase/navbarmain";
import Usuariologado from "../../componentes/layoutBase/usuariologado";
import {useLocation, useNavigate} from "react-router-dom";
import Loading from "../../componentes/loading";
import MensagemDeSucesso from "../../componentes/mensagemDeSucesso";
import MensagemDeErro from "../../componentes/mensagemDeErro";
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-date-picker";
import Dentista from "../../componentes/dentista";
import ReactToPrint from 'react-to-print';
import {TratamentoParaImpressao} from "../../componentes/tratamentoParaImpressao";

export default function TratamentosDoPaciente() {
    const URL_PADRAO = require('../../api/url.json').api;
    const api = axios.create({baseURL: URL_PADRAO});
    const location = useLocation();
    const navigate = useNavigate();
    const componentRef = useRef();
    const [isLoading, setLoading] = useState(false);
    const [exibeMensagemDeSucesso, setExibeMensagemDeSucesso] = useState(false);
    const [mensagemDeSucesso, setMensagemDeSucesso] = useState('');
    const [exibeMensagemDeErro, setExibeMensagemDeErro] = useState(false);
    const [mensagemDeErro, setMensagemDeErro] = useState('');
    const [nomeDoPaciente] = useState(location.state.nomeDoPaciente);
    const [telefoneDoPaciente] = useState(location.state.telefoneDoPaciente);
    const [pacienteId] = useState(location.state.pacienteId);

    // ############## TRATAMENTO
    const [tratamentoId, setTratamentoId] = useState(0);
    const [tratamentoSelecionado, setTratamentoSelecionado] = useState('');
    const [descricao, setDescricao] = useState('');
    const [idDoDentistaSelecionado, setIdDoDentistaSelecionado] = useState('');
    const [convenio, setConvenio] = useState('');
    const [tratamentosDoPaciente, setTratamentosDoPaciente] = useState([]);
    const [exibeModalDeAdicionarTratamento, setExibeModalDeAdicionarTratamento] = useState(false);
    const [exibeModalDeExclusaoDoTratamento, setExibeModalDeExclusaoDoTratamento] = useState(false);
    const [tratamentoParaExclusao, setTratamentoParaExclusao] = useState('');
    const [exibeModalParaAssinarTratamento, setExibeModalParaAssinarTratamento] = useState(false);
    const [tratamentoParaAssinar, setTratamentoParaAssinar] = useState('');
    const [tratamentoParaImpressao, setTratamentoParaImpressao] = useState('');

    // ############## PROCEDIMENTO
    const [exibeModalDeProcedimento, setExibeModalDeProcedimento] = useState(false);
    const [exibeModalDeExclusaoDoProcedimento, setExibeModalDeExclusaoDoProcedimento] = useState(false);
    const [exibeModalDeEvolucaoDoProcedimento, setExibeModalDeEvolucaoDoProcedimento] = useState(false);
    const [exibeModalComTodasEvolucoesDoProcedimento, setExibeModalComTodasEvolucoesDoProcedimento] = useState(false);
    const [exibeModalDeVisualizacaoDeProcedimento, setExibeModalDeVisualizacaoDeProcedimento] = useState(false);

    const [dentesDeciduosSuperiores, setDentesDeciduosSuperiores] = useState([]);
    const [dentesDeciduosInferiores, setDentesDeciduosInferiores] = useState([]);
    const [dentesPermanentesSuperiores, setDentesPermanentesSuperiores] = useState([]);
    const [dentesPermanentesInferiores, setDentesPermanentesInferiores] = useState([]);
    const [checkBoxDeciduoSelecionado, setCheckBoxDeciduoSelecionado] = useState(true);
    const [descricaoDoProcedimento, setDescricaoDoProcedimento] = useState('');
    const [observacaoDoProcedimento, setObservacaoDoProcedimento] = useState('');
    const [valorDoProcedimento, setValorDoProcedimento] = useState('');
    const [dentesSelecionados, setDentesSelecionados] = useState([]);
    const [procedimentosDoTratamento, setProcedimentosDoTratamento] = useState([]);
    const [procedimentoParaExclusao, setProcedimentoParaExclusao] = useState('');
    const [procedimentoParaEvolucao, setProcedimentoParaEvolucao] = useState('');
    const [procedimentoParaVisualizacao, setProcedimentoParaVisualizacao] = useState('');

    const [descricaoDaEvolucaoDoProcedimento, setDescricaoDaEvolucaoDoProcedimento] = useState('');
    const [dataDaEvolucaoDoProcedimento, setDataDaEvolucaoDoProcedimento] = useState('');
    const [evolucoesDoProcedimento, setEvolucoesDoProcedimento] = useState([]);

    const [todosDeciduosSelecionado, setTodosDeciduosSelecionado] = useState(false);
    const [todosPermanentesSelecionado, setTodosPermanentesSelecionado] = useState(false);

    const denteTodosDeciduos = {
        "name": "TODOS_DECIDUOS",
        "descricao": "Todos Deciduos",
        "selecionado": true
    };
    const denteTodosPermanentes = {
        "name": "TODOS_PERMANENTES",
        "descricao": "Todos Permanentes",
        "selecionado": true
    };

    // ############## FIM PROCEDIMENTO

    function exibirMensagemDeErro() {
        setExibeMensagemDeSucesso(false);
        setExibeMensagemDeErro(true);
    }

    function exibirMensagemDeSucesso() {
        setExibeMensagemDeSucesso(true);
        setExibeMensagemDeErro(false);
    }

    useEffect(() => {
        obterTratamentosDoPaciente(pacienteId);
    }, []);

    function obterTratamentosDoPaciente(pacienteId) {
        setLoading(true);
        api.get("/tratamento/paciente/".concat(pacienteId), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setTratamentosDoPaciente(resposta.data);
                setLoading(false);
            })
            .catch((erro) => {
                console.error(erro);
                setLoading(false);
            });
    }

    const selecionarDentista = (event) => {
        setIdDoDentistaSelecionado(event.target.value);
    }

    const selecionarConvenio = (event) => {
        setConvenio(event.target.value);
    }

    function abrirModalDeAdicionarTratamento() {
        limparCamposDoTratamento();
        setExibeModalDeAdicionarTratamento(true);
    }

    function setarTratamentoParaImpressao(tratamento) {
        setTratamentoParaImpressao(tratamento.tratamentoParaImpressao);
    }

    const adicionarTratamento = (event) => {
        event.preventDefault();
        setLoading(true);
        const tratamento = {
            "descricao": descricao,
            "dentistaId": idDoDentistaSelecionado,
            "convenio": convenio,
            "pacienteId": pacienteId
        }
        api.post("/tratamento", tratamento, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Tratamento cadastrado com sucesso!');
                obterTratamentosDoPaciente(pacienteId);
                setExibeModalDeAdicionarTratamento(false);
                limparCamposDoTratamento();
            })
            .catch((erro) => {
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                setLoading(false);
                setExibeModalDeAdicionarTratamento(false);
                console.error(erro);
            });
    }

    function abrirTratamento(tratamento) {
        setTratamentoId(tratamento.id);
        setTratamentoSelecionado(tratamento);
        setDescricao(tratamento.descricao);
        setConvenio(tratamento.convenio);
        setIdDoDentistaSelecionado(tratamento.dentistaId);
        obterProcedimentosDoTratamento(tratamento.id);
        setExibeMensagemDeSucesso(false);
    }

    function limparCamposDoTratamento() {
        setTratamentoId(0);
        setDescricao('');
        setConvenio('');
        setIdDoDentistaSelecionado('');
    }

    function abrirModalDeExclusaoDeTratamento(tratamento) {
        setExibeModalDeExclusaoDoTratamento(true);
        setTratamentoParaExclusao(tratamento);
    }

    function abrirModalParaAssinarTratamento(tratamento) {
        setExibeModalParaAssinarTratamento(true);
        setTratamentoParaAssinar(tratamento);
    }

    const assinarTratamento = (event) => {
        event.preventDefault();
        setLoading(true);
        const tratamento = {
            "id": tratamentoParaAssinar.id
        }
        api.post("/tratamento/assinar", tratamento, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setExibeModalParaAssinarTratamento(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Tratamento assinado com sucesso!');
                obterTratamentosDoPaciente(pacienteId);
            })
            .catch((erro) => {
                setLoading(false);
                setExibeModalParaAssinarTratamento(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
    }

    const removerTratamento = (event) => {
        event.preventDefault();
        setLoading(true);
        api.delete("/tratamento/".concat(tratamentoParaExclusao.id), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Tratamento removido com sucesso!');
                obterTratamentosDoPaciente(pacienteId);
                limparCamposDoTratamento();
                setExibeModalDeExclusaoDoTratamento(false);
            })
            .catch((erro) => {
                setLoading(false);
                setExibeModalDeExclusaoDoTratamento(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
    }

// ############## PROCEDIMENTO
    function obterDentes() {
        api.get("/dente/deciduos/superiores", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setDentesDeciduosSuperiores(resposta.data);
            })
            .catch((erro) => {
                console.error(erro);
            });

        api.get("/dente/deciduos/inferiores", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setDentesDeciduosInferiores(resposta.data);
            })
            .catch((erro) => {
                console.error(erro);
            });

        api.get("/dente/permanentes/superiores", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setDentesPermanentesSuperiores(resposta.data);
            })
            .catch((erro) => {
                console.error(erro);
            });

        api.get("/dente/permanentes/inferiores", {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setDentesPermanentesInferiores(resposta.data);
            })
            .catch((erro) => {
                console.error(erro);
            });
    }

    function obterProcedimentosDoTratamento(tratamentoId) {
        api.get("/procedimento/tratamento/".concat(tratamentoId), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setProcedimentosDoTratamento(resposta.data);
            })
            .catch((erro) => {
                console.error(erro);
            });
    }

    function abrirModalDeProcedimento() {
        obterDentes();
        setExibeModalDeProcedimento(true);
    }

    function abrirProcedimento(procedimento) {
        setProcedimentoParaVisualizacao(procedimento);
        setExibeModalDeVisualizacaoDeProcedimento(true);
    }

    const adicionarEvolucaoDoProcedimento = (event) => {
        event.preventDefault();
        setLoading(true);
        const evolucao = {
            "data": dataDaEvolucaoDoProcedimento,
            "descricao": descricaoDaEvolucaoDoProcedimento,
            "procedimentoId": procedimentoParaEvolucao.id
        };
        api.post("/procedimento/evolucao", evolucao, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setExibeModalDeEvolucaoDoProcedimento(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Evolução cadastrado com sucesso!');
                limparCamposDaEvolucaoDoProcedimento();
                obterProcedimentosDoTratamento(tratamentoSelecionado.id);
                obterTratamentosDoPaciente(pacienteId);
            })
            .catch((erro) => {
                setLoading(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
    }

    function obterDentesSelecionados() {
        dentesSelecionados.map((dente) => {
            if (dente.name === 'TODOS_DECIDUOS') {
                const indexDente = dentesSelecionados.indexOf(dente);
                dentesSelecionados.splice(indexDente, 1);
            }
        });
        if (todosDeciduosSelecionado) {
            dentesSelecionados.push(denteTodosDeciduos);
        }

        dentesSelecionados.map((dente) => {
            if (dente.name === 'TODOS_PERMANENTES') {
                const indexDente = dentesSelecionados.indexOf(dente);
                dentesSelecionados.splice(indexDente, 1);
            }
        });
        if (todosPermanentesSelecionado) {
            dentesSelecionados.push(denteTodosPermanentes);
        }

        return dentesSelecionados;
    }

    const adicionarProcedimento = (event) => {
        event.preventDefault();
        setLoading(true);
        const procedimento = {
            "descricao": descricaoDoProcedimento,
            "observacao": observacaoDoProcedimento,
            "tratamentoId": tratamentoSelecionado.id,
            "valor": valorDoProcedimento,
            "dentesSelecionados": obterDentesSelecionados()
        };
        api.post("/procedimento", procedimento, {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                setExibeModalDeProcedimento(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Procedimento cadastrado com sucesso!');
                limparCamposDoProcedimento();
                obterProcedimentosDoTratamento(tratamentoSelecionado.id);
                obterTratamentosDoPaciente(pacienteId);
            })
            .catch((erro) => {
                setLoading(false);
                setExibeModalDeProcedimento(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
    }

    function limparCamposDoProcedimento() {
        setDescricaoDoProcedimento('');
        setObservacaoDoProcedimento('');
        setDentesSelecionados([]);
        setValorDoProcedimento('');
        obterDentes();
    }

    function limparCamposDaEvolucaoDoProcedimento() {
        setDataDaEvolucaoDoProcedimento('');
        setDescricaoDaEvolucaoDoProcedimento('');
    }


    function selecionarTodosDeciduos() {
        setTodosPermanentesSelecionado(false);
        setTodosDeciduosSelecionado(!todosDeciduosSelecionado);
        setDentesSelecionados([]);
        dentesDeciduosSuperiores.map((dente) => {
            dente.selecionado = false;
        });
        dentesDeciduosInferiores.map((dente) => {
            dente.selecionado = false;
        });
    }

    function selecionarTodosPermanentes() {
        setTodosDeciduosSelecionado(false);
        setTodosPermanentesSelecionado(!todosPermanentesSelecionado);
        setDentesSelecionados([]);
        dentesPermanentesSuperiores.map((dente) => {
            dente.selecionado = false;
        });
        dentesPermanentesInferiores.map((dente) => {
            dente.selecionado = false;
        });

    }

    function selecionarDenteDeciduoSuperior(dente) {
        const dentesAtualizados = [];
        dentesDeciduosSuperiores.map((denteDaLista) => {
            if (dente.name === denteDaLista.name) {
                denteDaLista.selecionado = !denteDaLista.selecionado;
                if (denteDaLista.selecionado) {
                    dentesSelecionados.push(dente);
                } else {
                    const indexDente = dentesSelecionados.indexOf(dente);
                    dentesSelecionados.splice(indexDente, 1);
                }
            }
            dentesAtualizados.push(denteDaLista);
        });
        setDentesDeciduosSuperiores(dentesAtualizados);
    }

    function selecionarDenteDeciduoInferior(dente) {
        const dentesAtualizados = [];
        dentesDeciduosInferiores.map((denteDaLista) => {
            if (dente.name === denteDaLista.name) {
                denteDaLista.selecionado = !denteDaLista.selecionado;
                if (denteDaLista.selecionado) {
                    dentesSelecionados.push(dente);
                } else {
                    const indexDente = dentesSelecionados.indexOf(dente);
                    dentesSelecionados.splice(indexDente, 1);
                }
            }
            dentesAtualizados.push(denteDaLista);
        });
        setDentesDeciduosInferiores(dentesAtualizados);
    }

    function selecionarDentePermanenteInferior(dente) {
        const dentesAtualizados = [];
        dentesPermanentesInferiores.map((denteDaLista) => {
            if (dente.name === denteDaLista.name) {
                denteDaLista.selecionado = !denteDaLista.selecionado;
                if (denteDaLista.selecionado) {
                    dentesSelecionados.push(dente);
                } else {
                    const indexDente = dentesSelecionados.indexOf(dente);
                    dentesSelecionados.splice(indexDente, 1);
                }
            }
            dentesAtualizados.push(denteDaLista);
        });
        setDentesPermanentesInferiores(dentesAtualizados);
    }

    function selecionarDentePermanenteSuperior(dente) {
        const dentesAtualizados = [];
        dentesPermanentesSuperiores.map((denteDaLista) => {
            if (dente.name === denteDaLista.name) {
                denteDaLista.selecionado = !denteDaLista.selecionado;
                if (denteDaLista.selecionado) {
                    dentesSelecionados.push(dente);
                } else {
                    const indexDente = dentesSelecionados.indexOf(dente);
                    dentesSelecionados.splice(indexDente, 1);
                }
            }
            dentesAtualizados.push(denteDaLista);
        });
        setDentesPermanentesSuperiores(dentesAtualizados);
    }

    function abrirModalComTodasEvolucoesDoProcedimento(procedimento) {
        api.get("/procedimento/".concat(procedimento.id).concat("/evolucoes"), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setEvolucoesDoProcedimento(resposta.data);
            })
            .catch((erro) => {
                console.error(erro);
            });
        setExibeModalComTodasEvolucoesDoProcedimento(true);
    }

    function abrirModalDeEvolucaoDoProcedimento(procedimento) {
        setExibeModalDeEvolucaoDoProcedimento(true);
        setProcedimentoParaEvolucao(procedimento);
    }

    function abrirModalDeExclusaoDeProcedimento(procedimento) {
        setExibeModalDeExclusaoDoProcedimento(true);
        setProcedimentoParaExclusao(procedimento);
    }

    const removerProcedimento = (event) => {
        event.preventDefault();
        setLoading(true);
        api.delete("/procedimento/".concat(procedimentoParaExclusao.id), {
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then((resposta) => {
                setLoading(false);
                exibirMensagemDeSucesso();
                setMensagemDeSucesso('Procedimento removido com sucesso!');
                obterProcedimentosDoTratamento(tratamentoSelecionado.id);
                obterTratamentosDoPaciente(pacienteId);
            })
            .catch((erro) => {
                setLoading(false);
                exibirMensagemDeErro();
                setMensagemDeErro(erro.response.data);
                console.error(erro);
            });
        setExibeModalDeExclusaoDoProcedimento(false);
    }

// ############## FIM PROCEDIMENTO
    function irParaDadosGerais() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/prontuario", {state: dadosDoPaciente});
    }

    function irParaAnamnese() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/anamnese", {state: dadosDoPaciente});
    }

    function irParaDocumentos() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/documentos", {state: dadosDoPaciente});
    }

    function irParaEvolucoesGerais() {
        const dadosDoPaciente = {
            "nomeDoPaciente": nomeDoPaciente,
            "telefoneDoPaciente": telefoneDoPaciente,
            "pacienteId": pacienteId
        };
        navigate("/evolucoesgerais", {state: dadosDoPaciente});
    }

    return (
        <main className="main" id="top">
            {isLoading && <Loading/>}
            <div className="container" data-layout="container">
                <NavBarMain/>
                <div className="content">
                    <Usuariologado/>
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="row justify-content-between align-items-center">
                                <div className="col col-lg-8 mb-4">
                                    <h5 className="mb-0">Prontuário do Paciente</h5>
                                </div>
                                <div className="d-flex">
                                    <div className="avatar avatar-xl">
                                        <img className="rounded-circle" src="/images/thumb.png" alt=""/>
                                    </div>
                                    <div className="flex-1 fs--1">
                                        <h5 className="fs-0">{nomeDoPaciente}</h5>
                                        <p className="mb-0">{telefoneDoPaciente}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-light">
                            <div className="col-12 col-sm-auto ms-auto">
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaDadosGerais()}>Dados
                                    Gerais
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        disabled={true}>Tratamentos
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaAnamnese()}>Anamnese
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaDocumentos()}>Documentos
                                </button>
                                <button className="btn btn-falcon-primary m-md-1" type="button"
                                        onClick={() => irParaEvolucoesGerais()}>Evoluções Gerais
                                </button>
                            </div>
                        </div>
                        {exibeMensagemDeSucesso &&
                            <MensagemDeSucesso mensagem={mensagemDeSucesso}/>}
                        {exibeMensagemDeErro &&
                            <MensagemDeErro mensagem={mensagemDeErro}/>}

                        <div id="procedimento-div" className="card mb-3 d-print-none" hidden={tratamentoId === 0}>
                            <div className="card-header">
                                <div className="row flex-between-center">
                                    <div className="col col-lg-8">
                                        <h5 className="mb-0">Procedimentos</h5>
                                        <span>do {tratamentoSelecionado.descricao}</span>
                                    </div>
                                    <div className="col-12 col-sm-auto ms-auto">
                                        <a className="btn btn-falcon-primary" type="button"
                                           hidden={tratamentoSelecionado.status === 'ASSINADO'}
                                           onClick={() => abrirModalDeProcedimento()}>
                                            Adicionar Procedimento
                                        </a>
                                    </div>
                                </div>
                                <div className="text-center" hidden={procedimentosDoTratamento.length > 0}>
                                    <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">
                                        Nenhum procedimento cadastrado ainda.
                                    </p>
                                </div>
                                <div className="card-body border-top p-0"
                                     hidden={procedimentosDoTratamento.length === 0}>
                                    <div className="row g-0 align-items-center border-bottom py-2 px-3">
                                        <div className="table-responsive scrollbar">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Dente</th>
                                                    <th scope="col">Procedimento</th>
                                                    <th scope="col">Valor</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {procedimentosDoTratamento.map((procedimento) => {
                                                        return <tr key={procedimento.id}>
                                                            <td>
                                                                {procedimento.dentePorExtenso}
                                                            </td>
                                                            <td>
                                                                {procedimento.descricao}
                                                            </td>
                                                            <td>
                                                                {procedimento.valor}
                                                            </td>
                                                            <td>
                                                                {procedimento.descricaoDoStatus}
                                                            </td>
                                                            <td className="text-end">
                                                                <div>
                                                                    <a className="btn btn-link" title="Visualizar Procedimento"
                                                                       onClick={() => abrirProcedimento(procedimento)}>
                                                                        <span className="fas fa-eye"></span>
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-link" type="button" title="Excluir Procedimento"
                                                                        hidden={tratamentoSelecionado.status === 'ASSINADO'}
                                                                        onClick={() => abrirModalDeExclusaoDeProcedimento(procedimento)}>
                                                                    <span className="far fa-trash-alt"></span>
                                                                </button>
                                                            </td>
                                                            <td className="text-end">
                                                                <div>
                                                                    <a className="btn btn-link"
                                                                       hidden={procedimento.status === 'CRIADO'}
                                                                       onClick={() => abrirModalComTodasEvolucoesDoProcedimento(procedimento)}>
                                                                        Ver Evoluções
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td className="text-end">
                                                                <div>
                                                                    <a className="btn btn-link"
                                                                       hidden={tratamentoSelecionado.status != 'ASSINADO'}
                                                                       onClick={() => abrirModalDeEvolucaoDoProcedimento(procedimento)}>
                                                                        Adicionar Evolução
                                                                    </a>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }
                                                )}
                                                </tbody>
                                            </table>
                                            <Modal show={exibeModalDeExclusaoDoProcedimento} centered
                                                   onHide={() => setExibeModalDeExclusaoDoProcedimento(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Você realmente deseja remover o
                                                        procedimento?</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>{procedimentoParaExclusao.descricao}</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary"
                                                            onClick={() => setExibeModalDeExclusaoDoProcedimento(false)}>
                                                        Cancelar
                                                    </Button>
                                                    <Button variant="danger" onClick={removerProcedimento}>
                                                        Remover
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal show={exibeModalDeVisualizacaoDeProcedimento} centered
                               onHide={() => setExibeModalDeVisualizacaoDeProcedimento(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>{procedimentoParaVisualizacao.descricao}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <table className="table table-borderless fw-medium font-sans-serif fs--1 mb-0">
                                    <tbody>
                                    <tr class="hover-actions-trigger">
                                        <td className="p-1">Valor:</td>
                                        <td className="p-1 text-600">
                                            {procedimentoParaVisualizacao.valor}
                                        </td>
                                    </tr>
                                    <tr class="hover-actions-trigger">
                                        <td className="p-1">Dente:</td>
                                        <td className="p-1 text-600">
                                            {procedimentoParaVisualizacao.dentePorExtenso}
                                        </td>
                                    </tr>
                                    <tr class="hover-actions-trigger">
                                        <td className="p-1">Observação:</td>
                                        <td className="p-1 text-600">
                                            {procedimentoParaVisualizacao.observacao}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Modal.Body>
                        </Modal>


                        <Modal show={exibeModalComTodasEvolucoesDoProcedimento} size="lg" centered
                               onHide={() => setExibeModalComTodasEvolucoesDoProcedimento(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Evoluções do Procedimento</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div class="card-body  recent-activity-body-height ps-2">
                                    {evolucoesDoProcedimento.map((evolucao) => {
                                            return <div key={evolucao.descricao}>
                                                <div class="row g-3 timeline timeline-primary timeline-past pb-x1">
                                                    <div class="col-auto ps-4 ms-2">
                                                        <div class="ps-2">
                                                            <div
                                                                class="icon-item icon-item-sm rounded-circle bg-200 shadow-none">
                                                                <span class="text-primary fas fa-file-alt"></span></div>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="row gx-0 border-bottom pb-x1">
                                                            <div class="col">
                                                                <h6 class="text-800 mb-1">{evolucao.data}</h6>
                                                                <p class="fs--1 text-600 mb-0">{evolucao.descricao}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    )}
                                </div>
                            </Modal.Body>
                        </Modal>

                        <Modal show={exibeModalDeEvolucaoDoProcedimento} size="lg" centered
                               onHide={() => setExibeModalDeEvolucaoDoProcedimento(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Evolução do Procedimento</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form onSubmit={adicionarEvolucaoDoProcedimento}>
                                    <div className="col-md-4">
                                        <label className="form-label">Data</label>
                                        <DatePicker value={dataDaEvolucaoDoProcedimento} className="form-control"
                                                    onChange={(date) => setDataDaEvolucaoDoProcedimento(date)}
                                                    format="dd/MM/yyyy"></DatePicker>
                                    </div>
                                    <div className="mb-3">
                                        <label className="col-form-label">Descrição:</label>
                                        <textarea className="form-control" type="text"
                                                  value={descricaoDaEvolucaoDoProcedimento}
                                                  onChange={event => setDescricaoDaEvolucaoDoProcedimento(event.target.value)}/>
                                    </div>
                                    <button className="btn btn-primary" type="submit">Adicionar Evolução</button>
                                </form>
                            </Modal.Body>
                        </Modal>

                        <Modal show={exibeModalDeProcedimento} size="xl" centered
                               onHide={() => setExibeModalDeProcedimento(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Procedimento</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form onSubmit={adicionarProcedimento}>
                                    <div className="mb-3">
                                        <label className="col-form-label">Descrição:</label>
                                        <input className="form-control" type="text" value={descricaoDoProcedimento}
                                               onChange={event => setDescricaoDoProcedimento(event.target.value)}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="col-form-label">Observação:</label>
                                        <textarea className="form-control" type="text" value={observacaoDoProcedimento}
                                                  onChange={event => setObservacaoDoProcedimento(event.target.value)}/>
                                    </div>
                                    <label className="col-form-label">Tipo:</label>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" id="inlineRadio1" type="radio"
                                               name="inlineRadioOptions" value="option1"
                                               data-bs-toggle="collapse"
                                               data-bs-target="#collapse1" aria-expanded="true"
                                               aria-controls="collapse1"
                                               checked={checkBoxDeciduoSelecionado}
                                               onChange={() => setCheckBoxDeciduoSelecionado(!checkBoxDeciduoSelecionado)}/>
                                        <label className="form-check-label" htmlFor="inlineRadio1">Decíduos</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" id="inlineRadio2" type="radio"
                                               name="inlineRadioOptions" value="option2"
                                               data-bs-toggle="collapse" data-bs-target="#collapse2"
                                               aria-expanded="true" aria-controls="collapse2"
                                               checked={!checkBoxDeciduoSelecionado}
                                               onChange={() => setCheckBoxDeciduoSelecionado(!checkBoxDeciduoSelecionado)}/>
                                        <label className="form-check-label" htmlFor="inlineRadio2">Permanentes</label>
                                    </div>

                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                            <div className="accordion-collapse collapse show" id="collapse1"
                                                 aria-labelledby="heading1"
                                                 data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div>
                                                        {dentesDeciduosSuperiores.map((dente) => {
                                                                return <div key={dente.name} className="form-check-inline ">
                                                                    <FormCheck type="checkbox" label={dente.descricao}
                                                                               disabled={todosDeciduosSelecionado}
                                                                               checked={dente.selecionado}
                                                                               onChange={() => selecionarDenteDeciduoSuperior(dente)}/>
                                                                </div>
                                                            }
                                                        )}
                                                    </div>
                                                    <div>
                                                        {dentesDeciduosInferiores.map((dente) => {
                                                                return <div key={dente.name} className="form-check-inline ">
                                                                    <FormCheck type="checkbox" label={dente.descricao}
                                                                               disabled={todosDeciduosSelecionado}
                                                                               checked={dente.selecionado}
                                                                               onChange={() => selecionarDenteDeciduoInferior(dente)}/>
                                                                </div>
                                                            }
                                                        )}
                                                    </div>
                                                    <div>
                                                        <FormCheck type="checkbox" label="Todos"
                                                                   checked={todosDeciduosSelecionado}
                                                                   onChange={() => selecionarTodosDeciduos()}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-collapse collapse" id="collapse2"
                                                 aria-labelledby="heading2"
                                                 data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    <div>
                                                        {dentesPermanentesSuperiores.map((dente) => {
                                                                return <div key={dente.name} className="form-check-inline ">
                                                                    <FormCheck type="checkbox" label={dente.descricao}
                                                                               disabled={todosPermanentesSelecionado}
                                                                               checked={dente.selecionado}
                                                                               onChange={() => selecionarDentePermanenteSuperior(dente)}/>
                                                                </div>
                                                            }
                                                        )}
                                                    </div>
                                                    <div>
                                                        {dentesPermanentesInferiores.map((dente) => {
                                                                return <div key={dente.name} className="form-check-inline ">
                                                                    <FormCheck type="checkbox" label={dente.descricao}
                                                                               disabled={todosPermanentesSelecionado}
                                                                               checked={dente.selecionado}
                                                                               onChange={() => selecionarDentePermanenteInferior(dente)}/>
                                                                </div>
                                                            }
                                                        )}
                                                    </div>
                                                    <div>
                                                        <FormCheck type="checkbox" label="Todos"
                                                                   checked={todosPermanentesSelecionado}
                                                                   onChange={() => selecionarTodosPermanentes()}/>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="col-form-label">Valor:</label>
                                        <div className="input-group">
                                            <span className="input-group-text">R$</span>
                                            <CurrencyInput className="form-control"
                                                           decimalSeparator="," groupSeparator="."
                                                           decimalScale="2"
                                                           defaultValue={valorDoProcedimento}
                                                           decimalsLimit={2}
                                                           onChange={event => setValorDoProcedimento(event.target.value)}/>
                                        </div>
                                    </div>

                                    <button className="btn btn-primary" type="submit">Adicionar Procedimento</button>
                                </form>
                            </Modal.Body>
                        </Modal>
                        <div className="card mb-3 d-print-none">
                            <div className="card-header">
                                <div className="row flex-between-center">
                                    <div className="col col-lg-8">
                                        <h5 className="mb-0">Tratamentos</h5>
                                    </div>
                                    <div className="col-12 col-sm-auto ms-auto">
                                        <a className="btn btn-falcon-primary" type="button"
                                           onClick={() => abrirModalDeAdicionarTratamento()}>
                                            Adicionar Novo Tratamento
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <Modal show={exibeModalDeAdicionarTratamento} centered size={"lg"}
                                   onHide={() => setExibeModalDeAdicionarTratamento(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Tratamento</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <form className="row g-3 card-body needs-validation" onSubmit={adicionarTratamento}>
                                        <div className="col-md-4">
                                            <label className="form-label">Convênio</label>
                                            <select className="form-select" aria-label="Default select example"
                                                    disabled={tratamentoSelecionado.status === 'ASSINADO'}
                                                    value={convenio}
                                                    onChange={selecionarConvenio}>
                                                <option value="INVALIDO">Selecione o convenio</option>
                                                <option value="PARTICULAR">Particular</option>
                                                <option value="UNIODONTO">Uniodonto</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Descrição</label>
                                            <input className="form-control" type="text"
                                                   disabled={tratamentoSelecionado.status === 'ASSINADO'}
                                                   value={descricao}
                                                   onChange={event => setDescricao(event.target.value)}/>
                                        </div>
                                        <div className="col-md-6">
                                            <Dentista dentistaSelecionado={idDoDentistaSelecionado}
                                                      selecionarDentista={selecionarDentista}
                                                      disable={tratamentoSelecionado.status === 'ASSINADO'}/>
                                        </div>
                                        <div className="col-12" hidden={tratamentoId > 0}>
                                            <button className="btn btn-primary" type="submit">Adicionar Tratamento
                                            </button>
                                        </div>
                                    </form>
                                </Modal.Body>
                            </Modal>


                            <div className="text-center" hidden={tratamentosDoPaciente.length > 0}>
                                <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">
                                    Nenhum tratamento cadastrado ainda.
                                </p>
                            </div>

                            <div className="card-body border-top p-0" hidden={tratamentosDoPaciente.length === 0}>
                                <div className="row g-0 align-items-center border-bottom py-2 px-3">
                                    <div className="table-responsive scrollbar">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Convênio</th>
                                                <th scope="col">Tratamento</th>
                                                <th scope="col">Valor</th>
                                                <th scope="col">Data de criação</th>
                                                <th scope="col">Status</th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tratamentosDoPaciente.map((tratamento) => {
                                                    return <tr key={tratamento.id}>
                                                        <td>
                                                            {tratamento.descricaoDoConvenio}
                                                        </td>
                                                        <td>
                                                            {tratamento.descricao}
                                                        </td>
                                                        <td>
                                                            {tratamento.valorTotal}
                                                        </td>
                                                        <td>
                                                            {tratamento.data}
                                                        </td>
                                                        <td>
                                                            {tratamento.descricaoDoStatus}
                                                        </td>
                                                        <td className="text-end">
                                                            <div>
                                                                <a className="btn btn-link" title="Ver Procedimentos"
                                                                   onClick={() => abrirTratamento(tratamento)}>
                                                                    <span className="far fa-file-alt"></span>
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td className="text-end">
                                                            <div>
                                                                <a className="btn btn-link" title="Assinar Tratamento"
                                                                   hidden={tratamento.status === 'ASSINADO'}
                                                                   onClick={() => abrirModalParaAssinarTratamento(tratamento)}>
                                                                    <span className="far fa-check-square"></span>
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-link" type="button" title="Excluir Tratamento"
                                                                    hidden={tratamento.status === 'ASSINADO'}
                                                                    onClick={() => abrirModalDeExclusaoDeTratamento(tratamento)}>
                                                                <span className="far fa-trash-alt"></span>
                                                            </button>
                                                        </td>
                                                        <td className="text-end py-2 align-middle white-space-nowrap text-end">
                                                            <div className="dropdown font-sans-serif position-static">
                                                                <button className="btn btn-falcon-default btn-sm"
                                                                        type="button" id="order-dropdown-0"
                                                                        data-bs-toggle="dropdown" data-boundary="viewport"
                                                                        aria-haspopup="true" aria-expanded="false"><span
                                                                    className="fas fa-ellipsis-h fs--1"></span></button>
                                                                <div className="dropdown-menu dropdown-menu-end border py-0"
                                                                     aria-labelledby="order-dropdown-0">
                                                                    <div className="py-2">
                                                                        <ReactToPrint
                                                                            trigger={() => {
                                                                                return <a className="dropdown-item"
                                                                                          style={{cursor: 'pointer'}}>
                                                                                    Imprimir Tratamento</a>;
                                                                            }}
                                                                            content={() => componentRef.current}
                                                                            onBeforeGetContent={() =>
                                                                                new Promise((resolve) => {
                                                                                    resolve(setarTratamentoParaImpressao(tratamento));
                                                                                })
                                                                            }
                                                                        />
                                                                        <TratamentoParaImpressao ref={componentRef}
                                                                                                 tratamento={tratamentoParaImpressao}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            )}
                                            </tbody>
                                        </table>
                                        <Modal show={exibeModalDeExclusaoDoTratamento} centered
                                               onHide={() => setExibeModalDeExclusaoDoTratamento(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Você realmente deseja remover o tratamento?</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>O <strong>{tratamentoParaExclusao.descricao}</strong> e todos
                                                procedimentos,
                                                evoluções serão excluídos também.</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary"
                                                        onClick={() => setExibeModalDeExclusaoDoTratamento(false)}>
                                                    Cancelar
                                                </Button>
                                                <Button variant="danger" onClick={removerTratamento}>
                                                    Remover
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={exibeModalParaAssinarTratamento} centered
                                               onHide={() => setExibeModalParaAssinarTratamento(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Você realmente deseja assinar o tratamento?</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Após assinado, o tratamento e seus procedimentos não poderão
                                                ser alterados.</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary"
                                                        onClick={() => setExibeModalParaAssinarTratamento(false)}>
                                                    Cancelar
                                                </Button>
                                                <Button variant="primary" onClick={assinarTratamento}>
                                                    Assinar
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}